import {
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Container, maxWidth } from "@mui/system";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Fest from "../assets/components/Fest";
import Seo from "../assets/components/seo";
import Navbar from "../assets/components/Navbar";
import Heading from "../assets/components/Heading";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { Brightness1 } from "@mui/icons-material";
import Footer from "../assets/components/Footer";

const Festival = () => {
  const matches = useMediaQuery("(min-width: 900px)");
  const { t } = useTranslation();
  const resultadosUrl = t("resultadosUrl");

  const [loading, setLoading] = useState(false);
  const [buttonSelected, setButtonSelected] = useState(
    t("buttons", { returnObjects: true })[0]
  );

  const handleShowFest = (button) => {
    setLoading(true);
    setButtonSelected(button);
    setTimeout(() => setLoading(false), 1000);
  };

  return (
    <Container
      maxWidth={false}
      sx={{ padding: "0 !important", margin: "0 !important" }}
    >
      <Seo />
      <Navbar />

      <Grid container justifyContent="center" px={2} pb={8} spacing={8}>
        <Grid item container maxWidth={1400} marginTop={12}>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h3"
                color="white"
                position="absolute"
                textAlign="center"
              >
                {t("principal")}
              </Typography>
              <img
                src="https://imagenturistica.tabasco.gob.mx/_data/i/upload/2023/07/03/20230703153036-31df74fb-me.jpg"
                alt="festival del chocolate tabasco"
                style={{
                  width: "100%",
                  height: 228,
                  objectFit: "cover",
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          direction="column"
          xs={12}
          spacing={1}
        >
          <Grid item>
            <Heading label={t("title")} />
          </Grid>
        </Grid>
        <Grid item container rowSpacing={4} maxWidth={1400}>
          <Grid item xs={12} md={6} pr={{ md: 6 }}>
            <Typography color="GrayText" textAlign="justify" lineHeight={1.5}>
              <Typography
                display="inline"
                color="#6C3F37"
                lineHeight={1.5}
                fontWeight="600"
              >
                {t("descriptionBold")}{" "}
              </Typography>
              {t("description")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src="https://imagenturistica.tabasco.gob.mx/i.php?/upload/2023/06/13/20230613141120-ca6a65a1-xx.jpg"
              alt="Logo"
              style={{
                width: "100%",
                maxWidth: 800,
                objectFit: "contain",
              }}
            />
          </Grid>
        </Grid>
        <Grid item container maxWidth={1400}>
          <Grid item xs={12} pb={4}>
            <Heading label="FESTIVAL 2023" />
          </Grid>
          <Grid item xs={12}>
            <Heading label={t("innovacionesTitle")} variant="outlined" />
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            spacing={4}
            pb={4}
          >
            {t("innovaciones", { returnObjects: true }).map((innovacion, i) => (
              <Grid
                item
                container
                xs={12}
                md
                textAlign="center"
                justifyContent="center"
              >
                <Grid item>
                  <img
                    src={innovacion.url}
                    alt={innovacion.title}
                    style={{ width: "100%", maxWidth: 200 }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    textAlign="center"
                    fontWeight="bold"
                    color="#6c3f37"
                  >
                    {innovacion.title}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12}>
            <Heading label={t("concursosTitle")} variant="outlined" />
          </Grid>
          <Grid item container spacing={2}>
            {t("concursos", { returnObjects: true })
              .filter((item) => item.image != "")
              .map((concurso, i) => (
                <Grid item xs={12} md={4}>
                  <img src={concurso.image} alt={concurso.name} />
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item container maxWidth={1400}>
          <Grid item xs={12} pb={4}>
            <Heading label={t("resultadoTitle")} />
          </Grid>

          <Grid item container rowSpacing={2}>
            <Grid item xs={12} md={5}>
              <Typography color="#6c3f37" variant="h6" fontWeight="bold">
                {t("subtitle")}
              </Typography>
              <List>
                {t("resultados", { returnObjects: true }).map(
                  (resultado, i) => (
                    <ListItem style={{ paddingBottom: 2, paddingLeft: 0 }}>
                      <ListItemIcon style={{ minWidth: 25 }}>
                        <Brightness1 fontSize="inherit" htmlColor="#6c3f37" />
                      </ListItemIcon>
                      <ListItemText
                        primary={resultado}
                        key={i}
                        color="GrayText"
                      />
                    </ListItem>
                  )
                )}
              </List>
            </Grid>
            <Grid item xs={12} md={7}>
              <img
                src="https://imagenturistica.tabasco.gob.mx/_data/i/upload/2023/06/23/20230623150144-03ee1650-me.png"
                alt="resultados"
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <img
                src="https://imagenturistica.tabasco.gob.mx/_data/i/upload/2023/06/23/20230623150146-ff8a38f6-me.png"
                alt="resultados"
                style={{ maxWidth: 350, width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <img
                src={resultadosUrl}
                alt="resultados"
                style={{ maxWidth: 700, width: "100%" }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container maxWidth={1400}>
          <Grid item xs={12} pb={4}>
            <Heading label={t("festivales")} />
          </Grid>

          <Grid item container border={1} borderColor="#bebebe">
            <ButtonGroup
              orientation={matches ? "horizontal" : "vertical"}
              variant="contained"
              aria-label="outlined primary button group"
              fullWidth
            >
              {t("buttons", { returnObjects: true }).map((button, key) => (
                <Button
                  key={key}
                  fullWidth
                  style={{
                    borderRadius: 0,
                    borderColor: "#bebebe",
                    borderWidth: 1,
                    color:
                      button.name === buttonSelected?.name
                        ? "#F55E03"
                        : "#885a56",
                    backgroundColor:
                      button.name === buttonSelected?.name
                        ? "#FFFFFF"
                        : "#F4F4F4",
                  }}
                  onClick={() => handleShowFest(button)}
                >
                  {button.name}
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
          <Grid
            item
            container
            padding={8}
            bgcolor="white"
            border={1}
            style={{
              borderLeftColor: "#bebebe",
              borderRightColor: "#bebebe",
              borderBottomColor: "#bebebe",
              borderTopColor: "transparent",
            }}
          >
            {loading ? (
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <CircularProgress size={50} color="primary" />
                </div>
              </Grid>
            ) : (
              <Fest image={buttonSelected.image} text={buttonSelected.text} />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
};

export default Festival;
export const QUERY_TRANSLATION = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["festival"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
