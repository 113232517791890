import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  image: {
    padding: 15,
    width: "100%",
    maxWidth: 350,
    [theme.breakpoints.up("md")]: {
      float: "right",
    },
  },
}));

const Fest = ({ text, image }) => {
  const classes = useStyles();
  return (
    <Grid container bgcolor="white">
      <div style={{ flex: 1 }}>
        <img src={image} alt="Image 1" className={classes.image} />
        <Typography>{text}</Typography>
      </div>
    </Grid>
  );
};

export default Fest;
